const { nowInSec, SkyWayAuthToken, SkyWayContext, SkyWayRoom, SkyWayStreamFactory, uuidV4 } = skyway_room;

const isInstructor = document.getElementById('instructor') !== null;

function getToken() {
  const appId = "b9c0aacf-0c48-4c74-b352-079b03676570";
  const secret = "FW+D0z4ph3e2NA4adyRmPDplnoTWs/cLN+ab8CHaaBI=";

  const token = new SkyWayAuthToken({
    jti: uuidV4(),
    iat: nowInSec(),
    exp: nowInSec() + 60 * 60 * 24,
    scope: {
      app: {
        id: appId,
        turn: true,
        actions: ['read'],
        channels: [
          {
            id: '*',
            name: '*',
            actions: ['write'],
            members: [
              {
                id: '*',
                name: '*',
                actions: ['write'],
                publication: {
                  actions: ['write'],
                },
                subscription: {
                  actions: ['write'],
                },
              },
            ],

            sfuBots: [
              {
                actions: ['write'],
                forwardings: [{ actions: ['write'] }],
              },
            ],
          },
        ],
      },
    },
  }).encode(secret);
  return token
}

async function start() {
  const token = getToken()

  // init localVideo Tag
  const localVideo = document.getElementById('js-local-stream');
  const localData = document.getElementById('js-local-data');
  localVideo.muted = true;
  localVideo.playsInline = true;

  const joinTrigger = document.getElementById('js-join-trigger');
  const leaveTrigger = document.getElementById('js-leave-trigger');
  const remoteVideos = document.getElementById('js-remote-streams');
  const instructorVideo = document.getElementById('js-instructor-stream');
  const channelName = document.getElementById('js-channel-name');

  const roomMode = document.getElementById('js-room-type');
  // const messages = document.getElementById('js-messages');

  const sharingBrowserAudioTrigger = document.getElementById('startSharingBrowserAudio');
  const micMuteCB = document.getElementById('js-mute-trigger');
  const videoMuteCB = document.getElementById('js-video-mute-trigger');

  const userName = document.getElementById('js-user-name');
  const userNameSend = document.getElementById('js-user-name-send')
  const userMessage = document.getElementById('js-user-message');
  const userMessageSend = document.getElementById('js-user-message-send')
  const videoControl = document.getElementById('js-video-control')
  const userWordSend = document.querySelectorAll('.js-user-word-send');
  const userData = {
    name: '',
    message: '',
    word: {
      ja: '',
      en: ''
    }
  };

  const getRoomTypeByHash = () => (location.hash === '#sfu' ? 'sfu' : 'p2p');
  roomMode.textContent = getRoomTypeByHash();
  window.addEventListener('hashchange', () => {
    roomMode.textContent = getRoomTypeByHash();
  });

  const { audio: micAudio, video: cameraVideo } = await SkyWayStreamFactory.createMicrophoneAudioAndCameraStream();
  cameraVideo.attach(localVideo);
  await localVideo.play();

  let browserAudio;


  const context = await SkyWayContext.Create(token, {
    log: { level: 'warn', format: 'object' },
  });

  let room;

  // Register join handler
  joinTrigger.addEventListener('click', async () => {
    if (room) {
      return;
    }

    room = await SkyWayRoom.FindOrCreate(context, {
      name: channelName.value,
      type: getRoomTypeByHash(),
    });
    console.log('join', room)

    const member = await room.join();
    // messages.textContent += '=== You joined ===\n';
    const dataStream = await SkyWayStreamFactory.createDataStream();
    member.publish(dataStream)
    if (room.members.length > 11) {
      //人数制限
      member.leave()
    }

    room.onMemberJoined.add((e) => {
      // 誰かが入ったら
      console.log('onMemberJoined', e.member.room.members)
      // messages.textContent += `=== ${e.member.id.slice(0, 5)} joined ===\n`;
    });

    if (sharingBrowserAudioTrigger !== null) {
      sharingBrowserAudioTrigger.addEventListener('click', async () => {
        // 共有押したら
        ({ audio: browserAudio } = await SkyWayStreamFactory.createDisplayStreams({
          video: {},
          audio: true,
        }));//.catch( err => console.log(err));
        await member.publish(browserAudio, {
          metadata: 'browserAudio',
        });
      });
    }

    userNameSend.addEventListener('click', () => {
      // 名前送信
      dataStream.write(JSON.stringify(
        {
          userType: isInstructor ? 'instructor' : 'user',
          method: 'sendName',
          value: userName.value
        }
      ));
      userData.name = userName.value;
      localData.querySelector('.name').innerText = userData.name;
      if (userData.name !== '') {
        localData.querySelector('.name').classList.add('d-block');
      } else {
        localData.querySelector('.name').classList.remove('d-block');
      }
      userName.value = ''
    })
    userMessageSend.addEventListener('click', () => {
      // メッセージ送信
      dataStream.write(JSON.stringify(
        {
          userType: isInstructor ? 'instructor' : 'user',
          method: 'sendMessage',
          value: userMessage.value
        }
      ))
      userData.message = userMessage.value;
      localData.querySelector('.message').innerText = userData.message;
      if (userData.message !== '') {
        localData.querySelector('.message').classList.add('d-block');
      } else {
        localData.querySelector('.message').classList.remove('d-block');
      }
      userMessage.value = ''
    })
    userWordSend.forEach((element) => {
      element.addEventListener('click', () => {
        const value = element.getAttribute('data-value');
        const text = element.innerText;
        if (userData.word.ja === value) {
          //word消す
          userData.word.ja = '';
          userData.word.en = '';
        } else {
          userData.word.ja = value;
          userData.word.en = text;
        }
        //wordを送信
        dataStream.write(JSON.stringify(
          {
            userType: isInstructor ? 'instructor' : 'user',
            method: 'sendWord',
            value: userData.word.ja
          }
        ));
        localData.querySelector('.word').innerText = userData.word.en;
        if (userData.word.en !== '') {
          localData.querySelector('.word').classList.add('d-block');
        } else {
          localData.querySelector('.word').classList.remove('d-block');
        }
      })
    })

    let users = [];

    // Playing remotePublication
    member.onPublicationSubscribed.add(async ({ stream, subscription }) => {
      console.log('onPublicationSubscribed')
      // 受信 
      const publisherId = subscription.publication.publisher.id;
      const findUser = users.find((user) => {
        return user.id === publisherId
      })
      dataStream.write(JSON.stringify(
        {
          userType: isInstructor ? 'instructor' : 'user',
          method: 'requestUserType',
        }
      ))
      if (findUser === undefined) {
        // userが未定義の場合、element作成
        const element = document.createElement('div')
        element.id = publisherId
        element.classList.add('stream', 'p-0', 'position-relative')
        element.innerHTML = `
        <div class="data">
        <div class="name"></div>
        <div class="message"></div>
        <div class="word"></div>
        </div>`
        users.push({
          id: publisherId,
          element,
          isAppend: false
        })
      }
      const user = users.find((user) => {
        return user.id === publisherId
      })
      if (user === undefined) {
        console.error('Userがありません')
        return
      }

      if (stream.contentType === 'data') {
        // データを受信した場合
        // userType user instructor
        // value 値
        // valueType name message
        stream.onData.add((data) => {
          try {
            const parseData = JSON.parse(data)
            if (parseData.method === 'sendName') {
              user.element.querySelector('.data .name').innerText = parseData.value;
              if (parseData.value === '') {
                user.element.querySelector('.data .name').classList.remove('d-block');
              } else {
                user.element.querySelector('.data .name').classList.add('d-block');
              }
            }
            if (parseData.method === 'sendMessage') {
              user.element.querySelector('.data .message').innerText = parseData.value;
              if (parseData.value === '') {
                user.element.querySelector('.data .message').classList.remove('d-block');
              } else {
                user.element.querySelector('.data .message').classList.add('d-block');
              }
            }
            if (parseData.method === 'sendWord') {
              user.element.querySelector('.data .word').innerText = parseData.value;
              if (parseData.value === '') {
                user.element.querySelector('.data .word').classList.remove('d-block');
              } else {
                user.element.querySelector('.data .word').classList.add('d-block');
              }
            }
            if (parseData.method === 'requestUserType') {
              dataStream.write(JSON.stringify(
                {
                  userType: isInstructor ? 'instructor' : 'user',
                  method: 'sendUserType',
                }
              ))
            }
            if (parseData.method === 'sendUserType') {
              user.userType = parseData.userType;
              if (!user.isAppend) {
                if (user.userType === 'user') {
                  remoteVideos.append(user.element);
                }
                if (user.userType === 'instructor' && !isInstructor) {
                  instructorVideo.append(user.element);
                }
              }
              user.isAppend = true;
            }
            if (parseData.method === 'sendVideoMute') {
              const video = user.element.querySelector('video');
              if (parseData.value) {
                video.style.visibility = 'hidden';
              } else {
                video.style.visibility = 'visible';
              }
            }
          } catch (error) {
            console.error(error)
          }
          // messages.textContent += data
        })
        console.log('users', users)
        return
      }

      if (user.video === undefined) {
        // videoが無い場合、element作成
        const newVideo = document.createElement('video');
        newVideo.playsInline = true;
        newVideo.autoplay = true;
        newVideo.setAttribute(
          'data-member-id',
          subscription.publication.publisher.id
        );
        newVideo.classList.add('reversal')
        user.element.append(newVideo);
        user.video = newVideo;
      }
      if (user.audio === undefined) {
        // audioが無い場合、element作成
        const newAudio = document.createElement('audio');
        newAudio.autoplay = true;
        newAudio.setAttribute(
          'data-member-id',
          subscription.publication.publisher.id
        );
        user.element.append(newAudio);
        user.audio = newAudio;
      }
      if (subscription.publication.metadata === 'browserAudio') {
        // browserAudioの場合、audioに設定
        const newAudio = user.audio
        stream.attach(newAudio);
      } else {
        // その他の場合、videoに設定（映像とマイク）
        const newVideo = user.video;
        stream.attach(newVideo);
      }
      console.log('users', users)
    });

    //Subscribing other's publication
    const subscribe = async (publication) => {
      if (publication.publisher.id === member.id) return;
      await member.subscribe(publication.id);
    };

    // 後からPublishされたPublicationをSubscribing
    room.onStreamPublished.add((e) => subscribe(e.publication));

    // 入室時に存在するRoomPublicationsをsubscribing
    room.publications.forEach(subscribe);

    // Publish AudioStreams
    await member.publish(micAudio, {
      metadata: 'micAudio',
    });
    // if (browserAudio) {
    //   await member.publish(browserAudio, {
    //     metadata: 'browserAudio',
    //   });
    // }

    // Publish VideoStream
    if (room.type === 'sfu') {
      await member.publish(cameraVideo, {
        encodings: [
          { maxBitrate: 10_000, id: 'low' },
          { maxBitrate: 800_000, id: 'high' },
        ],
      });
    } else {
      await member.publish(cameraVideo);
    }
    //初期設定
    videoControl.classList.remove('d-none');
    micMuteCB.checked = false;
    if (!isInstructor) {
      micMuteCB.checked = true;
      toggleMicMute()
    }
    const disposeVideoElement = (remoteVideo) => {
      //Video消す
      const stream = remoteVideo.querySelector('video').srcObject;
      stream.getTracks().forEach((track) => track.stop());
      remoteVideo.querySelector('video').srcObject = null;
      remoteVideo.remove();
    };

    //*** switch Mic Mute ***///
    micMuteCB.addEventListener('change', toggleMicMute);
    async function toggleMicMute() {
      member.publications.forEach((publication) => {
        if (publication.contentType === 'audio') {
          if (micMuteCB.checked) {
            console.log('mute')
            publication.disable();
          } else {
            console.log('mute解除')
            publication.enable();
          }
        }
      });
    }

    videoMuteCB.addEventListener('change', toggleVideoMute);
    async function toggleVideoMute() {
      member.publications.forEach((publication) => {
        if (publication.contentType === 'video') {
          if (videoMuteCB.checked) {
            publication.disable();
            //videoMute送信
            dataStream.write(JSON.stringify(
              {
                userType: isInstructor ? 'instructor' : 'user',
                method: 'sendVideoMute',
                value: true
              }
            ))
          }
          else {
            publication.enable();
            //videoMute送信
            dataStream.write(JSON.stringify(
              {
                userType: isInstructor ? 'instructor' : 'user',
                method: 'sendVideoMute',
                value: false
              }
            ))
          }
        }
      });
    }

    room.onMemberLeft.add((e) => {
      //相手が退出
      console.log('onMemberLeft', users)
      if (e.member.id === member.id) return;
      const findUser = users.find(user => user.id === e.member.id);
      if (findUser === undefined) {
        return
      }
      if (findUser.userType === 'instructor') {
        const stream = instructorVideo.querySelector('video').srcObject;
        stream.getTracks().forEach((track) => track.stop());
        instructorVideo.querySelector('video').srcObject = null;
        instructorVideo.innerHTML = '';
      }
      else {
        const remoteVideo = remoteVideos.querySelector(`#${e.member.id}`);
        disposeVideoElement(remoteVideo);
      }
      users = users.filter(user => user.id !== e.member.id)

      // messages.textContent += `=== ${e.member.id.slice(0, 5)} left ===\n`;
    });

    member.onLeft.once(() => {
      //自分が退出
      console.log('onLeft')
      Array.from(remoteVideos.children).forEach((element) => {
        disposeVideoElement(element);
      });
      if (!isInstructor) {
        const stream = instructorVideo.querySelector('video').srcObject;
        stream.getTracks().forEach((track) => track.stop());
        instructorVideo.querySelector('video').srcObject = null;
        instructorVideo.innerHTML = '';
      }
      users = [];
      // messages.textContent += '== You left ===\n';
      room.dispose();
      room = undefined;
    });

    leaveTrigger.addEventListener('click', () => member.leave(), {
      once: true,
    });
  });
}
start().then(() => {

}).catch((error) => {
  console.error(error)
})

